




import { Vue, Component } from 'vue-property-decorator';
import HotelList from '@/modules/hotels/components/hotel-list.vue';

@Component({
    components: { HotelList },
})
export default class HotelListModalPage extends Vue {

}
