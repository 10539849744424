import VueRouter, { Route } from 'vue-router';

export default function getParentNonModalRoute(router: VueRouter, route: Route = router.currentRoute) {
    while (route.meta?.modal) {
        if (!route.name) break;
        const parentRouteName = route.name.split('.');
        parentRouteName.pop();

        // eslint-disable-next-line
        route = router.resolve({ name: parentRouteName.join('.') }).resolved;
    }

    return route.name || '';
}
